dmx.Component('serverconnect', {

    extends: 'fetch',

    attributes: {
        sockets: {
            type: Boolean,
            default: false
        }    
    },

    render: function(node) {
        if (this.props.sockets) {
            this.eventName = this.props.url.replace(/^(\.\.\/)*api\//, '');
            this.socket = dmx.Socket('/api');
            this.socket.on(this.eventName, this.refresh.bind(this));
            this.update({});
        } else {
            dmx.Component('fetch').prototype.render.call(this, node);
        }
    },

    fetch: function(options) {
        if (this.props.sockets) {
            this.refresh(options && options.params);
        } else {
            dmx.Component('fetch').prototype.fetch.call(this, options);
        }
    },

    refresh: function(params) {
        params = dmx.extend(true, {}, this.props.params, params || {});

        this.dispatchEvent('start');

        this.set('state', {
            executing: true,
            uploading: false,
            processing: true,
            downloading: false
        });
        
        this.socket.emit(this.eventName, params, function(res) {
            this.set('status', res.status);
            this.set('data', res.data);
            this.set('state', {
                executing: false,
                uploading: false,
                processing: false,
                downloading: false
            });

            if (res.status < 400) {
                this.dispatchEvent('success');
            } else if (res.status == 400) {
                this.dispatchEvent('invalid');
            } else if (res.status == 401) {
                this.dispatchEvent('unauthorized');
            } else if (res.status == 403) {
                this.dispatchEvent('forbidden');
            } else {
                this.dispatchEvent('error');
            }

            this.dispatchEvent('done');
        }.bind(this));
    }

});
